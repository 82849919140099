import { router } from '@inertiajs/vue3';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { type App } from 'vue';
import channels from '../channels';

export interface EchoOptions {
    pusher: {
        host?: string,
        port?: number,
        force_tls?: boolean
    }
};

export type UseEchoDeclarationSchema = Record<string, Record<any,UseEchoEvents>>;
export type EchoChannelName = 'private' | 'channel' | 'persistence';
export type UseEchoEvents = Record<any, UseEchoCallback>
export type UseEchoCallback = (...args: any[]) => void;

export type UseEchoDeclarationFunction<T = any> = (context: T) => UseEchoDeclarationSchema;

export type Broadcaster = 'null'|'reverb';

// @ts-expect-error Property 'Pusher' does not exist on type 'Window & typeof globalThis'.
window.Pusher = Pusher;

export const echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT,
    wssPort: import.meta.env.VITE_REVERB_PORT,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});

export default (Vue: App, props: any) => {
    router.on('before', (event) => {
        const id = echo.socketId();

        if(id) {
            event.detail.visit.headers['X-Socket-Id'] = id;
        }
    });
    
    if(props.authUser) {
        channels({ echo, props });
    }
};