<script setup lang="ts">
import { computed, ref, useSlots, type VNode } from 'vue';
import Tab from './Tab.vue';

const props = defineProps<{
    active?: number,
    flex?: boolean
}>();

defineSlots<{
    default: () => VNode[]
}>();

const slots = useSlots();

const tabs: any = computed(() => {
    return slots.default?.().filter(({ type }) => type === Tab);
});

const index = ref<number>(props.active ?? 0);
const activeSlot = computed(() => tabs.value?.[index.value]);
</script>

<template>
    <div class="flex flex-col gap-4">
        <div class="flex border-b border-neutral-300 dark:border-neutral-600">
            <button
                v-for="(tab, i) in tabs"
                :key="`tab-${i}`"
                type="button"
                class="-mb-px flex items-center justify-center gap-4 border-b border-neutral-300 p-3 transition-all duration-200 dark:border-neutral-600 dark:text-neutral-400 hover:dark:border-neutral-500 hover:dark:text-neutral-200"
                :class="{
                    'flex-1': flex,
                    '!border-rose-500 !text-rose-500': index == i
                }"
                @click="index = i">
                <Component
                    :is="tab.props.icon"
                    v-if="tab.props?.icon"
                    class="h-6 w-6" />
                {{ tab.props?.header }}
            </button>
        </div>

        <KeepAlive>
            <Component
                :is="activeSlot"
                :key="index" />
        </KeepAlive>
    </div>
</template>