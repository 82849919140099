<script lang="ts" setup>
import { ArrowDownTrayIcon, BeakerIcon, CalendarDaysIcon, ClipboardDocumentListIcon, CursorArrowRaysIcon, DocumentDuplicateIcon, EnvelopeIcon, ExclamationCircleIcon, ExclamationTriangleIcon, NoSymbolIcon, PaperAirplaneIcon, PencilSquareIcon, TrashIcon, VariableIcon } from '@heroicons/vue/24/outline';
import { ExclamationTriangleIcon as ExclamationTriangleIconSolid } from '@heroicons/vue/24/solid';
import { Link, router } from '@inertiajs/vue3';
import { BtnDropdown } from '@vue-interface/btn-dropdown';
import { Confirm } from '@vue-interface/modal';
import type { DatePickerMarker } from '@vuepic/vue-datepicker';
import { defaultConfig, lint, type Hint } from 'capsule-lint';
import { format } from 'date-fns';
import type { Agency, Audit, LengthAwarePaginator, Send, SendJob, SubscriberList, User } from 'types';
import { onBeforeMount, onBeforeUnmount, ref } from 'vue';
import MessageGearsIcon from '../../svg/message-gears-icon.svg';
import axios from '../axios';
import ActionButton from '../components/ActionButton.vue';
import Banner from '../components/Banner.vue';
import DetailsView, { Relationship, checkDataType } from '../components/DetailsView.vue';
import Header from '../components/Header.vue';
import HtmlPreview from '../components/HtmlPreview.vue';
import SearchableListGroup from '../components/SearchableListGroup.vue';
import SendScheduleForm from '../components/SendScheduleForm.vue';
import SendStatusBadge from '../components/SendStatusBadge.vue';
import SendTestForm from '../components/SendTestForm.vue';
import Show from '../components/Show.vue';
import Tab from '../components/Tab.vue';
import Tabs from '../components/Tabs.vue';
import BadgeDescription from '../components/audits/BadgeDescription.vue';
import Description from '../components/audits/Description.vue';
import IconDescription from '../components/audits/IconDescription.vue';
import { route } from '../composables/routes';
import { echo } from '../plugins/Echo';

export type ScheduledSends = {
    sends: Send[],
    markers: DatePickerMarker[]
}

const props = defineProps<{
    agency: Agency;
    error?: string;
    audits?: LengthAwarePaginator<Audit>;
    sendJobs?: LengthAwarePaginator<SendJob>;
    authUser: User;
    send: Send;
    list: SubscriberList;
    scheduled?: ScheduledSends;
}>();

const lintErrors = ref<Hint[]>(lint(props.send?.html ?? '', {
    ...defaultConfig, ...{
        'valid-path-format': false
    }
}));

const sendTestForm = ref();
const scheduleSendForm = ref();
const schedule = ref();
const dataVariables = props.send.options.mg_data_variables
    ?.filter(variable => variable.value !== variable.defaultValue)
    .reduce<Record<string,any>>((carry, value) => {
        return Object.assign(carry, {
            [value.label]: value.readableValue
        });
    }, {}) || {};

const dataVariableFields = Object.keys(dataVariables).map((key) => ({
    name: key,
    ...checkDataType(dataVariables?.[key]),
}));

function destroy(send: Send) {
    if(confirm(`Are you sure you want to delete "${send.name}"?`)) {
        router.delete(route('sends.destroy', {
            agency: props.agency.slug,
            send: send.id,
            list: props.list.slug,
        }));
    }
}

function download(send: Send) {
    const blob = new Blob([send.html ?? '']);

    const blobURL = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = blobURL;

    a.download = `${send.name}.html`;

    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(blobURL);
}

function onConfirmSendTest(button: HTMLButtonElement, context: { close: Function }) {
    button.disabled = true;

    sendTestForm.value.post().then(
        () => context.close(),
        () => button.disabled = false
    );
}

function onConfirmScheduleSend(button: HTMLButtonElement, context: { close: Function }) {
    button.disabled = true;
    
    scheduleSendForm.value.form.submit('patch', scheduleSendForm.value.url, {
        onError() {
            button.disabled = false;
        },
        onSuccess() {
            context.close();
        },
        only: ['scheduled', 'send']
    });
}

function onCancelSend() {
    if(confirm(`Are you sure you want to cancel "${props.send.name}"?`)) {
        axios.patch(route('sends.cancel', { 
            agency: props.agency.slug,
            list: props.list.slug,
            send: props.send.id
        })).then(() => router.reload());
    }
}

function onConfirmSendNow(button: HTMLButtonElement, context: {close: Function}) {
    button.disabled = true;

    axios.patch(route('sends.now', { 
        agency: props.agency.slug,
        list: props.list.slug,
        send: props.send.id
    })).then(() => {
        router.reload();        
        context.close();
    }, () => button.disabled = false);
}

function replicate() {
    if(confirm(`Are you sure you want to duplicate "${props.send.name}"?`)) {
        router.post(route('sends.replicate', {
            agency: props.agency.slug,
            send: props.send.id,
            list: props.list.slug,
        }), {});
    }
}

function createMessageGearsTemplate(send: Send) {
    if(confirm(`Are you sure you want to create "${send.name}" as a template in MessageGears?`)) {
        axios.post(`mg/template/${send.id}`).then(() => router.reload());
    }
}

function patchMessageGearsTemplate(send: Send) {
    if(confirm(`Are you sure you want to update "${send.name}" in MessageGears?`)) {
        axios.patch(`mg/template/${send.id}`).then(() => router.reload());
    }
}

function form(send: Send) {
    const fields = [
        {
            label: 'Name',
            name: 'name',
            valid: !!send.name
        },
        {
            label: 'HTML',
            name: 'html',
            valid: !!send.html
        },
        {
            label: 'Subject',
            name: 'subject',
            valid: !!send.subject
        },
        {
            label: 'From Name',
            name: 'options.mg_from_name',
            valid: !!send.options.mg_from_name
        }
    ];

    return {
        isValid: fields.every(item => item.valid),
        fields
    };
}

onBeforeMount(() => {
    echo.private(`App.Models.Send.${props.send.id}`)
        .listen('.SendUpdated', () => router.reload())
        .listen('.SendDeleted', () => router.reload())
        .listen('.AuditCreated', () => router.reload())
        .listen('.AuditUpdated', () => router.reload())
        .listen('.AuditDeleted', () => router.reload());
});

onBeforeUnmount(() => {
    echo.private(`App.Models.Send.${props.send.id}`)
        .stopListening('.SendUpdated')
        .stopListening('.SendDeleted')
        .stopListening('.AuditCreated')
        .stopListening('.AuditUpdated')
        .stopListening('.AuditDeleted');
});
</script>

<template>
    <Confirm
        v-if="['draft', 'failed'].includes(send.status)"
        trigger="#send-test"
        title="Send Test"
        close-button
        :colors="{
            info: 'bg-rose-100 text-rose-400',
        }"
        confirm-label="Send Test"
        :icon="BeakerIcon"
        @confirm="onConfirmSendTest">
        <SendTestForm
            ref="sendTestForm"
            :send="send"
            description="Enter recipient email addresses:"
            :auth-user="authUser" />
    </Confirm>

    <Confirm
        trigger="#schedule"
        title="Schedule Send"
        close-button
        :colors="{
            info: 'bg-rose-100 text-rose-400',
        }"
        :icon="false"
        confirm-label="Schedule"
        @confirm="onConfirmScheduleSend">
        <SendScheduleForm
            ref="scheduleSendForm"
            description="Select a date and time that you wish to schedule the send. Sends must be 5 minutes apart."
            :agency="agency"
            :list="list"
            :send="send"
            :scheduled="scheduled" />
    </Confirm>

    <Confirm
        trigger="#now"
        title="Send Now"
        close-button
        :colors="{
            info: 'bg-rose-100 text-rose-400',
        }"
        :icon="false"
        confirm-label="Send"
        @confirm="onConfirmSendNow">
        Are you sure you want to launch this send now?
    </Confirm>

    <Show
        :title="send.name"
        :icon="EnvelopeIcon">
        <template #description>
            <div v-if="send.send_at && send.status === 'scheduled'">
                {{ `Scheduled for ${format(new Date(send.send_at), 'PPPp')}` }}
            </div>
            <div v-else-if="send.delivered_at && send.status === 'delivered'">
                {{ `Delivered on ${format(new Date(send.delivered_at), 'PPPp')}` }}
            </div>
            <div v-else>
                {{ `Updated on ${format(new Date(send.updated_at), 'PPPp')}` }}
            </div>
        </template>
        
        <template #badges>
            <SendStatusBadge
                :send="send" />
        </template>

        <template #actions>
            <div
                class="flex items-center gap-4">
                <ActionButton>
                    <Link
                        v-if="send.can?.update && ['draft', 'failed', 'template'].includes(send.status)"
                        :href="route('sends.edit', {
                            agency: agency.slug,
                            send: send.id,
                            list: list.slug
                        })"
                        class="group flex items-center">
                        <PencilSquareIcon class="mr-3 h-5 w-5" /> Edit Send
                    </Link>
                    <button
                        v-if="send.can?.schedule && ['draft', 'failed'].includes(send.status)"
                        id="send-test"
                        class="group flex items-center">
                        <BeakerIcon class="mr-3 h-5 w-5" /> Send Test
                    </button>
                    <button
                        v-if="send.can?.create && ['delivered', 'template', 'draft'].includes(send.status)"
                        title="This will create a new send with the same content as this send."
                        class="group flex items-center"
                        @click="replicate()">
                        <DocumentDuplicateIcon class="mr-3 h-5 w-5" /> Duplicate Send
                    </button>
                    <template v-if="send.can?.manageMgTemplate && form(send).isValid">
                        <button
                            v-if="!send.mg_template_id && send.status === 'draft'"
                            class="group flex items-center"
                            @click="createMessageGearsTemplate(send)">
                            <MessageGearsIcon class="mr-3 h-5 w-5" /> Create as MessageGears Template
                        </button>
                        <button
                            v-if="send.mg_template_id && send.status === 'template'"
                            class="group flex items-center"
                            @click="patchMessageGearsTemplate(send)">
                            <MessageGearsIcon class="mr-3 h-5 w-5" /> Update MessageGears Template
                        </button>
                    </template>
                    <button
                        v-if="send.can?.create && send.html"
                        class="group flex items-center"
                        @click="download(send)">
                        <ArrowDownTrayIcon class="mr-3 h-5 w-5" /> Download HTML
                    </button>
                    <template v-if="send.can?.delete && ['draft', 'failed', 'template'].includes(send.status)">
                        <hr>
                        <button
                            class="group flex items-center"
                            @click="() => destroy(send)">
                            <TrashIcon class="my-1 mr-3 h-5 w-5" /> Delete Send
                        </button>
                    </template>
                </ActionButton>
                <BtnDropdown
                    v-show="['draft', 'failed'].includes(send.status) && send.can?.schedule && form(send).isValid && !send.has_recent_send_attempts"
                    variant="btn-primary"
                    label="Schedule">
                    <button
                        id="schedule"
                        ref="schedule"
                        class="flex items-center gap-2">
                        <CalendarDaysIcon class="h-5 w-5" />
                        Schedule
                    </button>
                    <button
                        id="now"
                        class="flex items-center gap-2">
                        <PaperAirplaneIcon class="h-5 w-5" />
                        Send Now
                    </button>
                </BtnDropdown>
                <button
                    v-show="send.status === 'scheduled' && send.can?.schedule"
                    class="btn-primary btn group flex items-center"
                    @click="onCancelSend()">
                    <NoSymbolIcon class="mr-2 h-6 w-6" /> Cancel Send
                </button>
            </div>
        </template>

        <Banner
            v-if="error"
            class="bg-rose-500 dark:bg-rose-500 text-neutral-200">
            {{ error }}
        </Banner>

        <Banner
            v-if="!form(send).isValid"
            class="!bg-amber-300 dark:!bg-purple-300 dark:!text-neutral-800 !text-neutral-900">
            <ExclamationTriangleIcon
                class="w-8 h-8" />
            The following fields are required:
            <template
                v-for="field in form(send).fields"
                :key="field.name">
                <div v-if="!field.valid">
                    <Link
                        v-if="!field.valid && send.can?.update"
                        :href="route('sends.edit', {                        
                            agency: agency.slug,
                            send: send.id,
                            list: list.slug,
                            field: field.name
                        })"
                        class="text-rose-500 underline">
                        {{ field.label }}
                    </Link>
                    <span
                        v-else-if="!field.valid">
                        {{ field.label }}
                    </span>
                </div>
            </template>
        </Banner>

        <Banner
            v-if="send.status === 'failed'"
            class="!bg-red-300 !text-red-800">
            <ExclamationTriangleIconSolid
                class="w-8 h-8 text-red-700" />
            <template v-if="send.has_recent_send_attempts">
                This send failed. You must wait at least 5 minutes before you can re-send. If you keep getting the same error, please contact support.
            </template>
            <template v-else>
                This send failed. Please contact support.
            </template>
        </Banner>

        <Banner
            v-if="send.status === 'delivered' && send?.campaign?.recipients === 0"
            class="!bg-amber-300 dark:!bg-purple-300 dark:!text-neutral-800 !text-neutral-900">
            <ExclamationTriangleIcon
                class="w-8 h-8" />
            This send was delivered to {{ send.campaign.recipients }} recipients. Please contact support.
        </Banner>

        <Banner
            v-if="lintErrors.length"
            class="bg-red-600 dark:bg-red-600 text-white">
            <ExclamationTriangleIcon
                class="w-8 h-8" />
            <div class="flex items-center justify-between flex-1">
                <div>
                    <div
                        v-for="(error, i) in lintErrors"
                        :key="i"
                        class="flex-1">
                        {{ error.message }}
                    </div>
                </div>
                <div class="flex justify-end">
                    <Link
                        :href="route('sends.edit', { agency: agency.slug, list: list.slug, send: send.id })"
                        class="btn btn-sm btn-white">
                        Fix Errors
                    </Link>
                </div>
            </div>
        </Banner>
        
        <div v-if="send.mailingid && send.status === 'delivered'">
            <Header
                title="Engagement"
                :icon="CursorArrowRaysIcon" />

            <Tabs>
                <Tab
                    v-if="send.campaign"
                    header="Campaign">
                    <DetailsView
                        :header="false"
                        :data="send.campaign"
                        :fields="[
                            { name: 'recipients', label: 'Recipients', type: Number },
                            { name: 'deliveries', label: 'Deliveries', type: Number },
                            { name: 'opens', label: 'Opens', type: Number },
                            { name: 'unique_opens', label: 'Unique Opens', type: Number },
                            { name: 'clicks', label: 'Clicks', type: Number },
                            { name: 'unique_clicks', label: 'Unique Clicks', type: Number },
                            { name: 'unsubs', label: 'Unsubs', type: Number },
                            { name: 'complaints', label: 'Complaints', type: Number },
                        ]" />
                </Tab>
                <Tab
                    v-if="send.clicks?.length"
                    header="Clicks">
                    <DetailsView
                        class="max-h-[400px] overflow-y-scroll"
                        :header="false"
                        :data="send.clicks"
                        :fields="[
                            { name: 'base_url', label: 'Links', type: Link },
                            { name: 'count', label: 'Click Count' },
                            { name: 'unique_count', label: 'Unique Click Count' },
                        ]" />
                </Tab>
            </Tabs>
        </div>
            
        <HtmlPreview
            :html="send.iframe_html ?? send.html ?? send.no_html_preview ?? ''" />

        <DetailsView
            :data="send"
            :fields="[
                (
                    send.type === 'bee' ? (
                        send.template?.can?.view ? {
                            name: 'template.name',
                            label: 'Template', 
                            type: Relationship, 
                            href: route('templates.show', {
                                agency: agency.slug,
                                list: list.slug,
                                template: send.template.id 
                            }) 
                        } : { name: 'template.name', label: 'Template' }
                    ) : undefined
                ),
                (
                    send?.can?.update && ['draft', 'failed', 'template'].includes(send.status) ? { 
                        name: 'subject',
                        label: 'Subject',
                        type: Relationship,
                        href: route('sends.edit', {
                            agency: agency.slug,
                            send: send.id,
                            list: list.slug,
                            field: 'subject'
                        })
                    } : { name: 'subject', label: 'Subject' }
                ),
                (
                    send?.can?.update && ['draft', 'failed', 'template'].includes(send.status) ? {
                        name: 'options.mg_from_name',
                        label: 'From Name',
                        type: Relationship, href:
                            route('sends.edit', {
                                agency: agency.slug,
                                send: send.id,
                                list: list.slug,
                                field: 'options.mg_from_name'
                            })
                    } : { name: 'options.mg_from_name', label: 'From Name'}
                ),                
                (
                    send?.can?.update && ['draft', 'failed', 'template'].includes(send.status) ? {
                        name: 'options.preheader',
                        label: 'Preheader',
                        type: Relationship,
                        href: route('sends.edit', {
                            agency: agency.slug,
                            send: send.id,
                            list: list.slug,
                            field: 'options.preheader'
                        })
                    } : { name: 'options.preheader', label: 'Preheader' }
                ),
                { name: 'list.options.mg_from_address', label: 'From Address' },
                (
                    send?.can?.update && ['draft', 'failed', 'template'].includes(send.status)
                        ? {
                            name: 'options.mg_reply_to_address',
                            label: 'Reply-To Address', 
                            type: Relationship, 
                            href: route('sends.edit', {
                                agency: agency.slug,
                                send: send.id,
                                list: list.slug,
                                field: 'options.mg_reply_to_address' 
                            })
                        } : { name: 'options.mg_reply_to_address', label: 'Reply-To Address'}
                ),
                authUser.super_admin && ['delivered', 'template'].includes(send.status) ? { name: 'mg_template_name', label: 'MessageGears Template' } : undefined,
                authUser.super_admin && ['delivered', 'template'].includes(send.status) ? { name: 'mg_template_id', label: 'MessageGears Template ID' } : undefined,
                send.status === 'delivered' ? { name: 'mailingid', label: 'Mailing ID' } : undefined,
                { name: 'updated_at', label: 'Updated', type: Date, format: 'PPPp' },
                { name: 'created_at', label: 'Created', type: Date, format: 'PPPp' },
            ]" />

        <DetailsView
            v-if="!!Object.keys(dataVariables).length"
            :data="dataVariables"
            header="Data Variables"
            :icon="VariableIcon"
            :fields="dataVariableFields" />

        <SearchableListGroup
            v-if="!!sendJobs?.data.length"
            header="Errors"
            size="md"
            :filters="false"
            :response="sendJobs">
            <template #badges="{ model }">
                <div
                    v-if="model.status_code"
                    class="badge bg-red-500">
                    Status: {{ model.status_code }}
                </div>
                <div
                    v-if="model.mailingid"
                    class="badge bg-sky-500">
                    Mailingid: {{ model.mailingid }}
                </div>
            </template>
            <template #title="{ model }">
                <template v-if="model.type === 'transactional_job'">
                    Failed Test
                </template>
                <template v-else>
                    Failed Marketing Campaign
                </template>
            </template>
            <template #description="{ model }">
                <template v-if="model.type === 'transactional_job'">
                    <div
                        v-for="(item, index) in model.response?.requestErrors"
                        :key="item">
                        {{ `${index + 1}. ${item.message}` }}
                    </div>
                    {{ format(new Date(model.created_at), 'PPPp') }}
                </template>
                <template v-else-if="model.type === 'marketing_campaign_job'">
                    {{ model.message ?? 'Unknown Error' }}<br>
                    {{ format(new Date(model.updated_at), 'PPPp') }}
                </template>
            </template>
            <template #icon="{ model }">
                <div class="relative">
                    <ExclamationCircleIcon class="size-8" />
                    <div class="absolute right-0 top-0 flex items-center justify-center size-5 -translate-y-1/3 translate-x-1/4 bg-red-500 rounded-full p-[3px]">
                        <BeakerIcon
                            v-if="model.type === 'transactional_job'"
                            class=" text-white size-5" />
                        <EnvelopeIcon
                            v-else
                            class=" text-white size-5" />
                    </div>
                </div>
            </template>
        </SearchableListGroup>

        <SearchableListGroup
            header="Activity"
            plural="audits"
            :icons="{
                default: ClipboardDocumentListIcon
            }"
            size="md"
            :filters="false"
            :response="audits">
            <template #icon="{ model }">
                <IconDescription
                    v-if="model.description?.icon"
                    :description="model.description.icon" />
            </template>
            <template #badges="{ model }">
                <BadgeDescription
                    v-if="model.description?.badge"
                    :description="model.description.badge" />
            </template>
            <template #title="{ model }">
                <Description
                    v-if="model.description"
                    :description="model.description" />
                <template v-else>
                    Audit description not available.
                </template>
            </template>
        </SearchableListGroup>
    </Show>
</template>